import React, { useState } from "react"
import useScript from "../service/useScript"
import heartCare from "../../static/assets/images/icons/heart_care_white.svg"
import drDummy from "../../static/assets/images/dr_dummy.jpg"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import { graphql, Link } from 'gatsby'
import { navigate } from "gatsby";
const StentPricing = ({ data: { allNodeTemplatePages: { edges }} }) => {

  useScript("/assets/js/cardiology.js")

  const [activeTab, setActiveTab] = useState(1)

  const [pageData] = edges;

  const metaTags = Array.isArray(pageData?.node?.metatag_normalized) && pageData?.node?.metatag_normalized.length > 0 ? pageData?.node?.metatag_normalized : [];
  const schema = pageData?.node?.relationships?.field_seo_schema ? pageData?.node?.relationships?.field_seo_schema : []
  const canonicalUrl = pageData?.node?.metatags_on_page || {}
  const title = typeof (pageData?.node?.title) !== "undefined" && pageData?.node?.title.length > 0 ? pageData?.node?.title : "StentPricing"

  const pageDetails = typeof (pageData) !== "undefined" && Array.isArray(pageData?.node?.relationships?.field_component_type) && pageData?.node?.relationships?.field_component_type.length > 0 && pageData?.node?.relationships?.field_component_type


  // basic icons and titles section
  const sectionData = pageDetails.find(el => el.name === "cardiology_care_icon_section");

  const sec1 = typeof (sectionData?.relationships?.components) !== "undefined" && sectionData?.relationships?.components.length > 0 && sectionData?.relationships?.components[0];
  const description = typeof (sec1?.description?.processed) !== "undefined" && sec1?.description?.processed.length > 0 ? sec1?.description?.processed : ""
  const icon = sec1 && sec1?.relationships?.image?.uri?.url;
  const iconAlt = sec1 && sec1?.image?.alt || "Hinduja Hospital";
  const secTitle = typeof (sec1?.title) !== "undefined" && sec1?.title.length > 0 ? sec1?.title : ""

  // tabs
  const tabsSection = pageDetails.find(el => el.name === "Tabular Format");
  const tabsSectionData = typeof (tabsSection?.relationships?.components) !== "undefined" && Array.isArray(tabsSection?.relationships?.components) && tabsSection?.relationships?.components




  return (
    <Layout>
      <Meta
        files={
          {
            js: [],
            css: [
              '/assets/css/services-detail.css',
              '/assets/css/about-through-the-ages.css',
              '/assets/css/accessibility.css',
            ]
          }
        }
        tags={{metaTags,canonicalUrl}}
      />
      <Schema schema={schema} />
      <main className="innerpage pt-first-section">
        <section className="speciality_section mt-4">
          <div className="container">
            <div className="section-head">
              <h2>{title}</h2>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <div className="aboutContainer diseaseContainer">
                  <ul className="nav nav-tabs gallery-tabs" role="tablist">
                    {
                      Array.isArray(tabsSectionData) && tabsSectionData.map((el, index) => {
                        return (
                          <li role="presentation" className="nav-item" key={index} style={{ padding: "0px 8px" }}>
                            <a className={`nav-link ${activeTab === index + 1 ? 'active' : ""}`} data-toggle="tab" href={`#${el?.field_title}`} role="tab" aria-controls={el?.field_title} aria-selected="true" onClick={() => setActiveTab(index + 1)} style={{ padding: "15px 48px" }}>{el?.field_title}</a>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div> */}
                <div className="about-ages">
                  <div className="tab-content mt-3">
                    {
                      Array.isArray(tabsSectionData) && tabsSectionData.map((el, index) => {
                        return (
                          <div className={`tab-pane ${activeTab === index + 1 ? 'active' : ""} `} id={el?.field_title} role="tabpanel" aria-labelledby={el?.field_title} key={index}>
                            <div className="card">
                              <div className="row">
                                <div className="col-md-12">
                                  <div dangerouslySetInnerHTML={{ __html: el?.text?.processed }}>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout >
  )
}


export const query = graphql`
query stentPricing {

  allNodeTemplatePages(filter: {path: {alias: {regex: "/stent-pricing$/"}}}) {

    edges {

      node {

        id

        title

        path {

          alias

        }
        relationships{
          field_seo_schema{
              field_seo_title
              field_seo_schema_text_area
          }
        }
        metatag_normalized {

          attributes {

            content

            name
            property

          }

        }
        metatags_on_page: field_meta_tags{
          canonical_url
        }
        relationships {

          field_component_type {

            __typename

            ...ParagraphSection3

          }

        }

      }

    }

  }

}



fragment ParagraphSection3 on paragraph__section {

  id

  name: field_name

  relationships {

    components: field_components {

      __typename

      ...ParagraphTitleAndDescription3

    }

  }

}



fragment ParagraphTitleAndDescription3 on paragraph__title_and_description {

  id

  field_title

  text: field_description {

    processed

  }

  field_cta_link {

    title

    uri

  }

  field_text {

    processed

  }

}
`;


export default StentPricing